// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

// scss-docs-start btn-variant-mixin
@mixin button-variant(
  $background,
  $border,
  $color: color-contrast($background),
  $hover-background:
    if($color == $color-contrast-light, shade-color($background, $btn-hover-bg-shade-amount), tint-color($background, $btn-hover-bg-tint-amount)),
  $hover-border:
    if($color == $color-contrast-light, shade-color($border, $btn-hover-border-shade-amount), tint-color($border, $btn-hover-border-tint-amount)),
  $hover-color: color-contrast($hover-background),
  $active-background:
    if($color == $color-contrast-light, shade-color($background, $btn-active-bg-shade-amount), tint-color($background, $btn-active-bg-tint-amount)),
  $active-border:
    if($color == $color-contrast-light, shade-color($border, $btn-active-border-shade-amount), tint-color($border, $btn-active-border-tint-amount)),
  $active-color: color-contrast($active-background),
  $disabled-background: $background,
  $disabled-border: $border,
  $disabled-color: color-contrast($disabled-background)
) {
  .btn-group:hover & {
    //box-shadow: 0 0 0 3px lighten($background, 5%);
  }

  color: $color;
  //@include gradient-bg($background);
  background-color: $background;
  border: 1px solid $background;
  transition: all 0.2s ease-in-out;
  //box-shadow: 0 0 0 1px lighten($background, 5%);

  &:hover:not(.no-hover) {
    color: $color;
    //@include gradient-bg($hover-background);
    background-color: darken($background, 15%);
    border-color: darken($background, 15%);
    //box-shadow: 0 0 0 3px $background;
    //box-shadow: 0 0 0 3px $background;

    .material-symbols-rounded {
      background-color: $hover-color;
    }
  }

  .material-symbols-rounded {
    background-color: $color;
  }

  .btn-check:focus + &,
  &:focus:not(.no-hover) {
    color: $hover-color;
    @include gradient-bg($hover-background);
    border-color: $hover-border;

    .material-symbols-rounded {
      background-color: $hover-color;
    }
  }

  .btn-check:checked + &,
  .btn-check:active + &,
  &:active:not(.no-hover),
  .show > &.dropdown-toggle {
    color: $active-color;
    background-color: $active-background;
    // Remove CSS gradients if they're enabled
    background-image: if($enable-gradients, none, null);
    border-color: $active-border;

    &:focus {
      @if $enable-shadows {
        @include box-shadow($btn-active-box-shadow, 0 0 0 $btn-focus-width rgba(mix($color, $border, 15%), 0.5));
      } @else {
        // Avoid using mixin so we can pass custom focus shadow properly
        box-shadow: 0 0 0 $btn-focus-width rgba(mix($color, $border, 15%), 0.5);
      }
    }

    .material-symbols-rounded {
      background-color: $active-color;
    }
  }

  &:disabled,
  &.disabled {
    color: $disabled-color;
    background-color: $disabled-background;
    // Remove CSS gradients if they're enabled
    background-image: if($enable-gradients, none, null);
    border-color: $disabled-border;

    .material-symbols-rounded {
      background-color: $disabled-color;
    }
  }
}
// scss-docs-end btn-variant-mixin

// scss-docs-start btn-outline-variant-mixin
@mixin button-outline-variant(
  $color,
  $color-hover: color-contrast($color),
  $active-background: $color,
  $active-border: $color,
  $active-color: color-contrast($active-background)
) {
  .btn-group:hover & {
    //box-shadow: 0 0 0 3px lighten($color, 5%);
  }

  color: lighten($color, 5%);
  //border-color: lighten($color, 90%);
  border: 1px solid lighten($color, 5%);
  transition: all 0.2s ease-in-out;
  //box-shadow: 0 0 0 1px lighten($color, 5%);
  background-color: transparentize($color, 0.98);
  //font-weight: 600;

  .material-symbols-rounded {
    background-color: $color;
    transition: all 0.2s ease-in-out;
  }

  &:hover:not(.no-hover) {
    //color: $color-hover;
    color: $color;
    background-color: transparentize($color, 0.9);
    //border-color: $active-border;
    //box-shadow: 0 0 0 3px $color;
    transition: all 0.2s ease-in-out, box-shadow 0.2s ease-in-out; // 0.2s;

    .material-symbols-rounded {
      background-color: $color;
    }
  }

  .btn-check:focus:not(.no-hover) + &,
  &:focus:not(.no-hover) {
    box-shadow: 0 0 0 $btn-focus-width rgba($color, 0.5);
  }

  .btn-check:checked + &,
  .btn-check:active + &,
  &:active:not(.no-hover),
  &.dropdown-toggle.show {
    //color: $active-color;
    // background-color: $active-background;
    // border-color: $active-border;
    background-color: transparentize($color, 0.9);
    //border-color: $active-border;
    box-shadow: 0 0 0 2px $color;

    // &:focus {
    //   @if $enable-shadows {
    //     @include box-shadow($btn-active-box-shadow, 0 0 0 $btn-focus-width rgba($color, 0.5));
    //   } @else {
    //     // Avoid using mixin so we can pass custom focus shadow properly
    //     box-shadow: 0 0 0 $btn-focus-width rgba($color, 0.5);
    //   }
    // }

    .material-symbols-rounded {
      background-color: $active-color;
    }
  }

  &:disabled,
  &.disabled {
    color: $color;
    background-color: transparent;

    .material-symbols-rounded {
      background-color: $color;
    }
  }
}
// scss-docs-end btn-outline-variant-mixin

// scss-docs-start btn-size-mixin
@mixin button-size($padding-y, $padding-x, $font-size, $border-radius) {
  padding: $padding-y $padding-x;
  //@include font-size($font-size);
  // Manually declare to provide an override to the browser default
  @include border-radius($border-radius, 0);
}
// scss-docs-end btn-size-mixin
