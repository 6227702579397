.form-section-control {
  label {
    color: #aaa;
    font-size: 0.7rem;
    font-weight: 600;
  }
}

.label--has-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
  line-height: 1;
  align-items: center;
  margin-bottom: 1rem;

  .alert {
    line-height: 1;
    margin-bottom: 0;
  }
}

.form-group:not(:last-child) {
  margin-bottom: 26px;
}

@keyframes form-control-error-fade-in {
  from {
    opacity: 0;
    transform: translateY(0.5rem);
  }

  to {
    opacity: 1;
    transform: translateY(-0.5rem);
  }
}

@keyframes form-control-error-fade-out {
  to {
    opacity: 0;
    transform: translateY(0.5rem);
  }

  from {
    opacity: 1;
    transform: translateY(-0.5rem);
  }
}

.form-section-control-error {
  font-size: 0.65rem;
  font-weight: 600;
  border-radius: 5px;
  color: $red;
  padding: 0 1.3rem;
  user-select: none;
  margin-top: -1rem;
  text-transform: lowercase;
  position: relative;
  z-index: 0;
  line-height: 1rem;
  height: 15px;
  opacity: 0;
  transform: translateY(-15px), rotateZ(0.001deg);

  &::first-letter {
    text-transform: uppercase !important;
  }

  &.show {
    animation: form-control-error-fade-in 0.2s ease-in-out forwards;
    z-index: 2;
  }

  &.hide {
    animation: form-control-error-fade-out 0.2s ease-in-out forwards;
  }

  .material-symbols-rounded {
    font-size: 0.6rem !important;
    background-color: $red;
  }
}

@keyframes form-error-fade-in {
  from {
    opacity: 0;
    transform: translateY(-15px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes form-error-fade-out {
  to {
    opacity: 0;
    transform: translateY(-15px);
  }

  from {
    opacity: 1;
    transform: translateY(0);
  }
}

.error-message {
  z-index: 99999;
  color: lighten($danger, 80%);
  position: absolute;
  display: flex;
  justify-content: center;
  align-content: flex-start;
  top: 0;
  left: 0;
  padding: 1rem;
  width: 100%;
  height: auto;

  &:not(.hide) {
    animation: form-error-fade-in 0.2s backwards;
  }

  &.sticky {
    position: fixed;
    top: 0;
  }

  &.hide {
    animation: form-error-fade-out 0.2s forwards;
  }

  span {
    backdrop-filter: blur(30px);
    font-size: 0.8rem;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 1rem;
    border-radius: 10px;
    padding: 0.7rem 1rem;
    border: 1px solid rgba($danger, 0.02);
    background-color: rgba(darken($danger, 20%), 0.6);
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.2);
  }

  .material-symbols-rounded {
    border: none;
    margin: 0.2rem;
    margin-right: 0;
    padding: 0;
    box-shadow: none;
    background-color: lighten($danger, 80%);
    font-size: 14px;
    cursor: pointer;
  }
}

.form {
  box-sizing: border-box;

  .form-section {
    border-top: 1px solid #ddd;
    margin: 0 -4rem;
    padding: 2rem 4rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .card & {
      margin: 0 -1.5rem;
      padding: 1.5rem;
    }

    // &:first-child:not(:only-child) {
    //   padding-bottom: 1.5rem;
    // }

    // &:nth-child(n + 2):not(:only-child) {
    //   padding-top: 1.5rem;
    // }

    &.form-section-divider {
      &::before {
        content: attr(data-label);
        margin: 0 auto;
        font-size: 10px;
        line-height: 12px;
        background-color: #fff;
        padding: 0 10px;
        padding-bottom: 0.5rem;
        margin-top: calc((1.5rem * -1) - 6px);
        font-weight: 400;
        text-transform: uppercase;
      }
    }

    .form-section-header,
    .form-section-footer {
      h5 {
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 0.2rem;
      }

      span {
        font-size: 0.9rem;
        display: block;
        color: #84868f;
        line-height: 1.1rem;
        margin-top: 0rem;
        margin-bottom: 1rem;

        & > span {
          line-height: 0rem;
        }
      }
    }

    .form-section-footer {
      justify-content: flex-end;
      gap: 0.5rem;
    }

    .form-section-control {
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }

    &:first-child {
      border: none;
      padding-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
}
