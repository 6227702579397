// .modal-open      - body class for killing the scroll
// .modal           - container to scroll within
// .modal-dialog    - positioning shell for the actual modal
// .modal-content   - actual modal w/ bg and corners and stuff

// Container that the modal scrolls within
.modal {
  position: fixed;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 19999;
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  padding: 0.5rem;

  &-scrollable &-dialog {
    max-height: calc(100vh - 1rem);

    .modal-content {
      overflow-y: auto;
      height: 100%;
    }
  }

  @include media-breakpoint-down(sm) {
    height: 100%;
  }
}

// Shell div to position the modal with bottom padding
.modal-dialog {
  width: auto;
  pointer-events: none;
  border-radius: 23px;
  overflow: hidden;
  box-shadow: 0 20px 30px -20px;

  // When fading in the modal, animate it to slide down
  .modal.fade & {
    @include transition($modal-transition);
    transform: $modal-fade-transform;
  }
  .modal.show & {
    transform: $modal-show-transform;
  }

  // When trying to close, animate focus to scale
  .modal.modal-static & {
    transform: $modal-scale-transform;
  }
}

@include media-breakpoint-up(sm) {
  .modal-dialog-scrollable {
    //height: subtract(100%, $modal-dialog-margin * 2);

    ::-webkit-scrollbar {
      overflow: overlay;
      width: 1rem;
      background-color: #eeeeee;
      background-clip: padding-box;
    }

    ::-webkit-scrollbar-track {
      background-color: #00000000;
      background: none;
      margin: 0.4rem;
    }

    ::-webkit-scrollbar-track-piece {
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #00000066;
      border-radius: 100px;
      border: 4px solid #00000000;
      background-clip: padding-box;
    }
  }
}

.modal-dialog-scrollable {
  .modal-content {
    max-height: 100vh;
    overflow: hidden;
    overflow-y: auto;
  }

  // .modal-header {
  //   position: sticky;
  //   top: 0;
  // }

  // .modal-footer {
  //   position: sticky;
  //   bottom: 0;
  // }
}

.modal-dialog-centered {
  display: flex;
  //min-height: subtract(100%, $modal-dialog-margin * 2);
}

// Actual modal
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%; // Ensure `.modal-content` extends the full width of the parent `.modal-dialog`
  color: $modal-content-color;
  pointer-events: auto;
  background-color: $modal-content-bg;
  box-shadow: $modal-content-box-shadow;
  outline: 0;
  z-index: 1052;
  background-color: #fff;
  overflow: hidden;
  background-clip: padding-box;
}

// Modal background
.modal-backdrop {
  backdrop-filter: blur(10px);

  @include overlay-backdrop($zindex-modal-backdrop, #00000055, 1);
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between; // Put modal header elements (title and dismiss) on opposite ends
  z-index: 20;
  background-color: #f6f6f6;
  padding: 2rem;
  padding-bottom: 1.5rem;
  font-weight: 600;
  letter-spacing: 0.03rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid var(--bs-gray-200);

  h5 {
    font-size: 1.1rem;
    margin-bottom: 0.2rem;
  }

  span {
    font-size: 1rem;
    font-weight: 500;
  }

  .btn-close {
    padding: ($modal-header-padding-y * 0.5) ($modal-header-padding-x * 0.5);
    margin: ($modal-header-padding-y * -0.5) ($modal-header-padding-x * -0.5) ($modal-header-padding-y * -0.5) auto;
  }

  &-search {
    margin-left: auto;
    max-width: 300px;
    background-color: #ffffff;
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;

    &-search {
      margin: 0 -0.3rem;
    }
  }
}

// Title text within header
.modal-title {
  margin-bottom: 0;
  line-height: 1.25rem;
}

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 2rem;
  overflow-y: auto;
  overflow-x: hidden;

  &--no-padding {
    padding: 0;
  }
}

// Footer (for actions)
.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: flex-end;
  justify-content: flex-end;
  z-index: 20;
  padding: 2rem;
  padding-top: 1rem;
  background-color: #f6f6f6;
  border-top: 1px solid var(--bs-gray-200);
  padding-top: 1.5rem;

  // Place margin between footer elements
  // This solution is far from ideal because of the universal selector usage,
  // but is needed to fix https://github.com/twbs/bootstrap/issues/24800
  > * {
    margin: $modal-footer-margin-between * 0.5;
  }
}

.modal-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(white, 0.2);
  backdrop-filter: blur(3px);
  z-index: 999;
  box-shadow: inset 0 0 50px rgba(white, 0.3);

  & svg {
    position: absolute;
    top: 50%;
    margin-top: -25px;
    left: 50%;
    margin-left: -25px;
  }
}

@include media-breakpoint-down(sm) {
  .modal {
    max-width: 100%;

    .modal-dialog {
      max-width: 100%;
      width: 100vw;
    }
  }

  .modal-dialog {
    margin-top: auto;
  }
}

// Scale up the modal
@include media-breakpoint-up(sm) {
  // Automatically set modal's width for larger viewports
  .modal-dialog {
    width: 70%;
    margin: $modal-dialog-margin-y-sm-up auto;
  }

  .modal-sm {
    max-width: 100%;

    .modal-dialog {
      max-width: 100%;
    }
  }

  .modal-md {
    max-width: 100%;

    .modal-dialog {
      max-width: 80%;
    }
  }
}

@include media-breakpoint-up(md) {
  .modal-sm {
    max-width: 100%;

    .modal-dialog {
      max-width: 50%;
    }
  }

  .modal-md {
    max-width: 100%;

    .modal-dialog {
      max-width: 70%;
    }
  }
}

@include media-breakpoint-up(lg) {
  .modal-sm {
    max-width: 100%;

    .modal-dialog {
      max-width: 50%;
    }
  }

  .modal-md {
    max-width: 100%;

    .modal-dialog {
      max-width: 60%;
    }
  }

  .modal-lg,
  .modal-xl {
    .modal-dialog {
      max-width: $modal-lg;
    }
  }
}

@include media-breakpoint-up(xl) {
  .modal-sm {
    max-width: 100%;

    .modal-dialog {
      max-width: 30%;
    }
  }

  .modal-md {
    max-width: 100%;

    .modal-dialog {
      max-width: 50%;
    }
  }

  .modal-xl {
    max-width: $modal-xl;
  }
}

// scss-docs-start modal-fullscreen-loop
@each $breakpoint in map-keys($grid-breakpoints) {
  $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
  $postfix: if($infix != '', $infix + '-down', '');

  @include media-breakpoint-down($breakpoint) {
    .modal-fullscreen#{$postfix} {
      width: 100vw;
      max-width: none;
      height: 100%;
      margin: 0;

      .modal-content {
        height: 100%;
        border: 0;
        @include border-radius(0);
      }

      .modal-header {
        @include border-radius(0);
      }

      .modal-body {
        overflow-y: auto;
      }

      .modal-footer {
        @include border-radius(0);
      }
    }
  }
}
// scss-docs-end modal-fullscreen-loop
