#root {
  height: 100vh;
  overflow: hidden;
}

.dashboard {
  display: flex;

  &::before,
  &::after {
    display: none !important;
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 1rem 1.5rem;
    padding-bottom: 15rem;
    flex-grow: 1;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    background: transparent !important;
    box-shadow: none !important;

    @include media-breakpoint-down(sm) {
      padding: 1.5rem;
      padding-bottom: 20rem;
      padding-top: 5.5rem;
    }

    .--empty {
      flex-grow: 1;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 1rem;

      @include media-breakpoint-down(sm) {
        margin-top: 6rem;
      }
    }

    @include media-breakpoint-up(sm) {
      padding: 3rem 4rem;
    }

    &__loader {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background-color: #ececec88;
      backdrop-filter: blur(3px);
      z-index: 99;

      & svg {
        position: absolute;
        top: 200px;
        left: 50%;
        margin-left: -15px;
      }
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    height: 100vh;
    background: transparent !important;
    box-shadow: none !important;

    @include media-breakpoint-down(sm) {
      //padding-top: 4rem;
    }
  }
}

.nav__sidebar {
  &::before,
  &::after {
    display: none !important;
  }
  
  * {
    box-shadow: none !important;
  }
}
