@import url('https://fonts.googleapis.com/css2?family=Your+Font:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'scss/bootstrap/bootstrap';

// Layout & Global Styling
@import 'scss/global/global';
@import 'scss/global/forms';
@import 'scss/global/layout';

// Components
@import 'scss/components/login';
@import 'scss/components/navigation';
@import 'scss/components/status-label';
@import 'scss/components/loader-spinner';
@import 'scss/components/card-table';
@import 'scss/components/linking-card';
@import 'scss/components/versions-collapse';
@import 'scss/components/welcome';

// Update the header styles to target nav__top with increased specificity
body .nav__top,
.nav__top {
  //This handles background to the header of the page
  background-color: white !important;
  // background-color: #0e7bbb !important;

  // Make sure the title text is visible against the blue background
  .nav__top-title {
    color: black;
    // This handles background to the font of the header of the page
    // background-color: white !important;
    //background-color: #0e7bbb !important;
    

  }
}


body .nav__sidebar,
.nav__sidebar {
  background-color: #0e7bbb !important;
  
  // Style the top section of the sidebar
  .nav__sidebar-header,
  .nav__sidebar-top,
  .organization-selector,
  .nav__sidebar > div:first-child {
    background-color: #0e7bbb !important;
    
    // Keep the organization selector dropdown white
    .dropdown-menu,
    .organization-select {
      background-color: white !important;
      
      a, span {
        color: #333 !important;
      }
    }
  }

  // Style the sidebar content
  a, span, .nav-link {
    color: white !important;
    
  }

  // Remove any box shadows or gradients
  &::after,
  &::before {
    display: none !important;
  }

  // Style for the organization selector and connectors
  .connector-item {
    background-color: white !important;
    border-radius: 8px;
    padding: 0.5rem 1rem;
    margin: 0.5rem 0;

    span, a {
      color: #333 !important;
    }
  }

  // Ensure icons maintain their original colors
  .connector-icon,
  .material-symbols-rounded.connector-icon {
    background-color: transparent !important;
    color: inherit !important;
  }

  // Remove any unwanted gradients or shadows
  .nav__sidebar-content {
    background-color: #0e7bbb !important;
  }

  .nav__sidebar-sticky {
    background-color: #0e7bbb !important;
  }

  // .nav__username {
  //   background-color: white !important;
  // }

  .nav__sidebar-wrapper {
    background-color: #0e7bbb !important;
    // background: transparent !important;
    box-shadow: none !important;
  }

  // Style for the CONNECTORS label
  .connectors-label {
    color: White !important;
    // color: rgba(255, 255, 255, 0.7) !important;
    font-size: 0.8rem;
    padding: 1rem 0 0.5rem;
  }
}
.nav__divider {
  color: white !important;
}

// Add these styles to ensure the card is visible against the background
.card {
  //This is the master card handling
  background-color: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(8px);
  border: 1px solid #ccc;
  // border: 1px solid #f2f1f0;
  // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

// Optional: Update text colors for better contrast
.light-text {
  color: white;
}

.dark-text {
  color: black;
}

.nav__top-links {
  color: black;
}

// Update styles for connector notes to be page-level
.connector-notes {
  margin-top: 1rem;
  padding: 1.5rem;
  background-color: #f8f9fa;
  border-left: 4px solid #0e7bbb;
  
  .notes-title {
    color: #0e7bbb;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .notes-text {
    p {
      color: #495057;
      font-weight: 500;
      margin-bottom: 0.75rem;
    }
    
    ul {
      padding-left: 1.2rem;
      margin-bottom: 0;
      
      li {
        margin-bottom: 0.5rem;
        color: #495057;
        
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
    border-radius: 8px;
    background-color: #e9ecef;
    
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 768px) {
    .video-container {
      margin-top: 1.5rem;
    }
  }
}