.nav {
  &__sidebar {
    min-width: 280px;
    max-width: 280px;
    height: 100vh;
    overflow: hidden;
    position: sticky;
    top: 0;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    z-index: 9999;

    ::-webkit-scrollbar {
      overflow: overlay;
      width: 2px;
      background-color: #eeeeee;
      background-clip: padding-box;
    }

    ::-webkit-scrollbar-track {
      background-color: #00000000;
      background: none;
      margin: 2px;
    }

    ::-webkit-scrollbar-track-piece {
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #00000066;
      border-radius: 100px;
      border: none;
      background-clip: padding-box;
    }

    &-sticky {
      position: sticky;
      top: 0;
      z-index: 10;
      background-color: #fff;
      box-shadow: 0 0 10px 10px #fff;
    }

    &-content,
    &-header,
    &-footer {
      padding: 1.5rem;
      transition: all 0.2s ease-in-out;
    }

    &-content {
      z-index: 11;
      overflow: hidden;
      overflow-y: auto;
    }

    &-header {
      min-height: 4rem;
      height: 4rem;
      border-bottom: 1px solid #dddddd99;
      padding: 0 1.5rem;
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    &-header,
    &-footer {
      z-index: 99;
    }

    &-footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      box-shadow: 0 0 20px 20px #fff;
    }

    @include media-breakpoint-up(sm) {
      &.minimised {
        min-width: calc(2.6rem + 24px);
        max-width: calc(2.6rem + 24px);
        padding: 0.5rem;
      }

      &.minimised &-content,
      &.minimised &-header,
      &.minimised &-footer {
        padding: 0rem;
      }

      &.minimised &-header {
        margin-bottom: 1rem;
      }
    }

    @include media-breakpoint-down(sm) {
      box-shadow: none;
      position: sticky;
      top: 0;

      &__username {
        display: none !important;
      }

      &-header,
      &-footer {
        box-shadow: 0 0 10px 10px #fff;
      }

      &.minimised {
        height: 4rem;
        overflow: hidden;
      }

      &.minimised &-header {
        border-bottom: 1px solid transparent;
      }

      &.minimised &-content {
        overflow-y: hidden;
      }
    }

    &.animate {
      transition: all 0.2s ease-in-out;
    }

    @include media-breakpoint-down(sm) {
      position: fixed;
      top: 0;
      left: 0;
      min-width: 0;
      max-width: 100vw;
      height: 100%;
      width: 100vw;
      z-index: 1040;
    }
  }

  @include media-breakpoint-up(sm) {
    &__sidebar-header &__username {
      display: none;
    }

    &__sidebar.minimised &__link {
      gap: 2rem;
      white-space: nowrap;
    }

    &__sidebar.minimised &__divider {
      opacity: 0;
      margin-top: -1.5rem;
    }

    &__sidebar.minimised &__logo {
      opacity: 0;
      margin-left: -120px;
    }

    &__sidebar.minimised &__username {
      padding: 0.4rem;
      margin: 0.2rem;
      margin-bottom: 0;
      gap: 2rem;
    }

    &__sidebar.minimised &__organisation {
      margin-bottom: 0.8rem;
    }

    &__sidebar.minimised &__organisation select {
      background-image: none;
      opacity: 0;
    }

    &__sidebar.minimised &__organisation-initial {
      opacity: 1;
    }
  }

  @include media-breakpoint-down(sm) {
    &__sidebar-header &__username {
      flex-grow: 1;
      margin: 0;
      margin-left: -0.7rem;
    }

    &__sidebar-content &__username {
      display: none;
    }
  }

  &__logo {
    height: 100%;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 0.8rem;

    .animate & {
      transition: all 0.2s ease-in-out;
    }

    img {
      width: 100px;
    }
  }

  &__toggle {
    margin-top: 0rem;
    margin-right: 0.8rem;
    margin-left: auto;
    z-index: 2;
    cursor: pointer;
    transition: margin-top 0.2s ease-in-out;
    display: flex;
    align-items: center;

    .material-symbols-rounded {
      background-color: #aaa;
      transition: all 0.2s ease-in-out;
    }

    &:hover .material-symbols-rounded {
      background-color: #333;
    }
  }

  &__divider {
    margin: 2rem 0 1rem;
    padding: 0 0.8rem;
    font-size: 0.6rem;
    font-weight: 700;
    color: #777;
    text-transform: uppercase;

    .animate & {
      transition: all 0.2s ease-in-out;
    }
  }

  &__divider_line {
    border-top: 1px solid #dddddd99;
    margin: 1rem -1.5rem;

    .animate & {
      transition: all 0.2s ease-in-out;
    }
  }

  &__link {
    cursor: pointer !important;
    font-size: 0.9rem;
    display: flex;
    flex-direction: row;
    padding: 0.8rem;
    color: #aaa;
    font-weight: 500;
    align-content: center;
    align-items: center;
    gap: 1rem;
    text-decoration: none !important;
    border-radius: 7px;
    margin-top: 2px;

    .animate & {
      transition: all 0.2s ease-in-out;
    }

    .material-symbols-rounded {
      background-color: #777;
    }

    &:not(.card):not(.active):hover {
      background-color: rgba($dark, 0.05);
      color: #aaa;
    }

    &.active {
      font-weight: 600;
      background-color: rgba($dark, 0.1);
      color: #555;

      .material-symbols-rounded {
        background-color: #777;
      }
    }

    i {
      font-size: 14px;
      margin-right: 15px;
    }

    &--skeleton-wrapper {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 50px;
        box-shadow: inset 0 -100px 50px -50px #fff;
      }
    }

    &--skeleton {
      cursor: default !important;

      &:hover {
        background: none !important;
      }

      @keyframes pulse {
        from {
          opacity: 0.2;
        }
        to {
          opacity: 1;
        }
      }

      animation: pulse 1.35s ease-in-out infinite alternate;

      &::before {
        content: '';
        height: 24px;
        width: 24px;
        min-height: 24px;
        min-width: 24px;
        border-radius: 50%;
        background-color: #00000044;
      }

      &::after {
        content: '';
        display: block;
        height: 18px;
        width: 20%;
        border-radius: 5px;
        background-color: #00000044;
      }

      &:nth-child(3n - 1) {
        &::after {
          width: 50%;
        }
      }

      &:nth-child(3n) {
        &::after {
          width: 30%;
        }
      }
    }

    &--main {
      position: relative;

      &.has-children:after {
        content: '\f107';
        font-family: 'Font Awesome 6 Free';
        font-weight: 900;
        display: block;
        font-style: normal;
        font-variant: normal;
        line-height: 1;
        text-rendering: auto;
        font-size: 14px;
        float: right;
        position: absolute;
        top: 50%;
        right: 40px;
        margin-top: -7px;
        transition: transform 0.5s ease-in-out;
        transform: rotate(180deg);
      }

      &.has-children.card:after {
        color: #333;
        right: 20px;
      }

      &.has-children.close:after {
        transition: transform 0.5s ease-in-out;
        transform: rotate(0deg);
      }

      &.has-children + .nav__sub-menu {
        overflow: hidden;
      }

      &.has-children.open + .nav__sub-menu {
        margin-bottom: 25px;
        padding-top: 20px;
        max-height: 1500px;
        transition: all 0.2s ease-in-out;
        opacity: 1;
      }

      &.has-children.close + .nav__sub-menu {
        margin-bottom: 0;
        padding-top: 0;
        max-height: 0;
        transition: all 0.2s ease-in-out;
        opacity: 0;
      }
    }

    &--sub {
      font-size: 12px;
      margin-left: 30px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
  }

  &__top {
    display: flex;
    flex-direction: column;
    top: 0;
    z-index: 999;
    border-bottom: 1px solid rgba(#000000, 0.05);
    padding: 1.5rem 1.5rem 0;
    background-color: rgba(236, 236, 236);

    @include media-breakpoint-up(sm) {
      padding: 2rem 4rem 0;
    }

    @include media-breakpoint-down(sm) {
      top: 4rem;
      //padding-top: 5.5rem;
      position: sticky;
    }

    &-title {
      margin: 0;
      margin-bottom: 2rem;
      text-transform: capitalize;
      height: 50px;
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: start;

      &-hide-search {
        &::after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          background-color: green;
          z-index: 2;
        }
      }

      @include media-breakpoint-down(sm) {
        height: auto;
        flex-direction: column;
        align-items: start;
        justify-content: start;
        margin-bottom: 1.5rem;
      }
    }

    &-links {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      font-size: 0.7rem;
      text-transform: uppercase;
      margin-top: -1rem;
      overflow: hidden;
      overflow-x: auto;
      z-index: 3;

      @include media-breakpoint-down(sm) {
        //margin-top: -1rem;
      }

      a {
        white-space: nowrap;
        padding-bottom: 0.5rem;
        text-decoration: none;
        color: #aaa;
        font-weight: 600;
        transition: all 0.2s ease-in-out;
        border-bottom: 2px solid #00000000;

        &.active {
          border-bottom: 2px solid $primary;
          color: darken($primary, 50%);
        }
      }
    }

    &-sublinks {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      font-size: 0.7rem;
      text-transform: uppercase;
      margin: 0 -4rem;
      padding-left: 4rem;
      border-top: 1px solid rgba(#000000, 0.05);

      a {
        padding-bottom: 0.5rem;
        margin-top: 0.7rem;
        text-decoration: none;
        color: #aaa;
        font-weight: 600;
        transition: border 0.2s ease-in-out, color 0.2s ease-in-out;
        border-bottom: 1px solid #00000000;

        &.active {
          border-bottom: 1px solid $dark;
          color: #555;
        }
      }
    }

    &-breadcrumbs {
      font-weight: 700;
      font-size: 17px;
    }

    &-divider {
      background-color: $gray-400;
      height: 50px;
      width: 1px;
    }

    & .page-search {
      margin-left: auto;
      max-width: 300px;
      min-width: 150px;
      z-index: 1;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    &--right {
      display: flex;
      align-items: center;
      gap: 18px;
    }
  }
}

.nav__username {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 700;
  font-size: 0.8rem;
  padding: 0.8rem 0.7rem;
  margin: 0 -0.5rem;
  margin-bottom: 1rem;
  white-space: nowrap;
  line-height: 0.8rem;
  border-radius: 100px;
  text-decoration: none;
  color: var(--bs-gray-700);
  cursor: pointer;

  &:hover {
    background-color: rgba($dark, 0.05);
    color: var(--bs-gray-700);
  }

  &.active {
    background-color: rgba($dark, 0.1);
  }

  .animate & {
    transition: all 0.2s ease-in-out;
  }

  &-initial {
    font-size: 0.7rem;
    font-weight: 700;
    color: #fff;
    background: $primary;
    min-height: 30px;
    min-width: 30px;
    max-height: 30px;
    max-width: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-logout {
    //background-color: lighten($dark, 40%);
    //background-clip: text;
    display: block;
    //margin-left: auto;
    outline: none !important;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none !important;
    margin: 0.6rem;
    min-height: 30px !important;
    max-height: 30px !important;
    min-width: 30px !important;
    max-width: 30px !important;
    padding-left: 2px !important;

    .material-symbols-rounded {
      font-size: 16px;
    }

    &.top {
      margin: 0;
      margin-left: auto;
      margin-right: 0.2rem;
    }

    &.bottom {
      opacity: 0;
      margin: 0;
      margin-left: -100px;

      .animate & {
        transition: all 0.2s ease-in-out, margin 0s linear 0.2s;
      }
    }

    .animate & {
      transition: all 0.2s ease-in-out;
    }

    &:hover {
      background-color: $dark;
    }
  }
}

.nav__organisation {
  position: relative;
  margin-bottom: 2rem;

  select {
    cursor: pointer;
    z-index: 5;
    position: relative;
    transition: none;
    padding-right: 3rem !important;

    .animate & {
      transition: all 0.2s ease-in-out;
    }
  }

  &-initial {
    display: flex;
    top: 0.6rem;
    left: 0.6rem;
    align-items: center;
    justify-content: center;
    position: absolute;
    border-radius: 300px;
    padding: 0.8rem;
    background-color: $dark;
    color: #fff;
    opacity: 0;
    z-index: 1;
    border: 1px solid $dark;
    font-size: 0.7rem;
    font-weight: bold;
    min-height: 30px;
    min-width: 30px;
    max-height: 30px;
    max-width: 30px;

    .animate & {
      transition: all 0.2s ease-in-out;
    }
  }
}
