//
// Base styles
//

.btn {
  font-size: 11px;
  line-height: 17px;
  height: 42px;
  display: inline-flex;
  flex-direction: row;
  gap: 0.5rem;
  font-family: $btn-font-family;
  font-weight: 600;
  color: $body-color;
  text-align: center;
  text-decoration: if($link-decoration == none, null, none);
  white-space: $btn-white-space;
  vertical-align: middle;
  cursor: if($enable-button-pointers, pointer, null);
  user-select: none;
  background-color: transparent;
  border: none;
  //margin: 2px;
  padding: 0.8rem 1.6rem;
  //border-radius: 0.8rem;
  white-space: nowrap;
  //@include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-border-radius);
  //@include border-radius($btn-border-radius, 0);
  //@include transition($btn-transition);

  border-radius: 10px;

  .material-symbols-rounded {
    font-size: 20px;
    margin-right: auto;
  }

  &:hover {
    color: $body-color;
    text-decoration: if($link-hover-decoration == underline, none, null);
  }

  .btn-check:focus + &,
  &:focus {
    outline: 0;
  }

  .btn-check:checked + &,
  .btn-check:active + &,
  &:active,
  &.active {
    &:focus {
    }
  }

  &:disabled,
  &.disabled,
  fieldset:disabled & {
    pointer-events: none;
    opacity: $btn-disabled-opacity;
  }

  &-round {
    border-radius: 50%;
    padding: 0;
    align-items: center;
    justify-content: center;
    max-height: 40px;
    max-width: 40px;
    min-height: 40px;
    min-width: 40px;

    &.btn-xs {
      padding: 0;
      align-items: center;
      justify-content: center;
      max-height: 26px;
      max-width: 26px;
      min-height: 26px;
      min-width: 26px;
    }

    .material-symbols-rounded {
      margin: auto;
    }
  }
}

//
// Alternate buttons
//

// scss-docs-start btn-variant-loops
@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}
// scss-docs-end btn-variant-loops

//
// Link buttons
//

// Make a button look and behave like a link
.btn-link {
  font-weight: $font-weight-normal;
  color: $btn-link-color;
  text-decoration: $link-decoration;

  &:hover {
    color: $btn-link-hover-color;
    text-decoration: $link-hover-decoration;
  }

  &:focus {
    text-decoration: $link-hover-decoration;
  }

  &:disabled,
  &.disabled {
    color: $btn-link-disabled-color;
  }

  // No need for an active state here
}

//
// Button Sizes
//

.btn-lg {
  @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-border-radius-lg);
}

// .btn-sm {
//   font-size: $btn-font-size-sm;
//   line-height: 17px;
//   height: 36px;

//   .material-symbols-rounded {
//     font-size: 17px;
//   }

//   // @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-border-radius-sm);
// }

.btn-xs {
  .material-symbols-rounded {
    font-size: 16px;
  }

  // @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-border-radius-sm);
}
